import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ScrollAndShow } from "../components/scroll-and-show";
import { Layout } from "../components/layout";
import { SEO } from "../components/seo";

import { app } from "../lib/firebaseClient";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";

export const db = getFirestore(app);

type Hour = {
	rubiaBrunch: string;
	rubiaDinner: string;
	tahona: string;
};

type RentalSpace = {
	popUpEvent: string;
	popUpEventEn: string;
	rentalSpace: string;
	rentalSpaceEn: string;
	privateParty: string;
	privatePartyEn: string;
	facility: string;
	facilityEn: string;
};

type PrivateParty = {
	policy: string;
	policyEn: string;
};

const MenuPage = () => {
	const [t, i18n] = useTranslation();

	const [hour, setHour] = useState<Hour>({
		rubiaBrunch: "",
		rubiaDinner: "",
		tahona: "",
	});

	const [rentalSpace, setRentalSpace] = useState<RentalSpace>({
		popUpEvent: "",
		popUpEventEn: "",
		rentalSpace: "",
		rentalSpaceEn: "",
		privateParty: "",
		privatePartyEn: "",
		facility: "",
		facilityEn: "",
	});

	const [privateParty, setPrivateParty] = useState<PrivateParty>({
		policy: "",
		policyEn: "",
	});

	useEffect(() => {
		const fetchHour = async () => {
			const docRef = await getDoc(doc(db, "rubia", "hour"));
			setHour(docRef.data() as Hour);
		};

		const fetchRentalSpace = async () => {
			const docRef = await getDoc(doc(db, "rubia", "rentalSpace"));
			setRentalSpace(docRef.data() as RentalSpace);
		};

		const fetchPrivateParty = async () => {
			const docRef = await getDoc(doc(db, "rubia", "privateParty"));
			setPrivateParty(docRef.data() as PrivateParty);
		};

		fetchHour();
		fetchRentalSpace();
		fetchPrivateParty();
	}, []);

	return (
		<Layout>
			<SEO
				title="MENU | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】"
				description="コンテンポラリーなスタイルでありながら、メキシコの伝統的な調理法を取り入れ、さらに四季あふれる日本ならではの旬の食材と日本料理に宿る精神も込めた、至高のメニューをご用意しています。豊富な種類のテキーラやメスカルを取り揃えており、日本とメキシコのコラボレーションをコンセプトにしたカクテルもお楽しみいただけます。"
				keywords={[
					"メキシコ料理",
					"渋谷",
					"ファインメキシカン",
					"ルビア",
					"sarasa",
				]}
			/>
			<h1 className="h1">MENU | RUBIA ルビア / DJ sarasa</h1>
			<div className="menu">
				<ScrollAndShow>
					<div className="md:flex md:justify-end">
						<div>
							<div className="hero-image-wrapper">
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/menu/menu.jpg"
									alt="menu"
								/>
								<div className="page-title font-lato">MENU</div>
							</div>
						</div>
					</div>
				</ScrollAndShow>

				<div className="menu-link-container font-lato">
					<ScrollAndShow>
						<div className="floor">
							<div className="floor-text">1F</div>
							<div className="floor-name-text restaurant">
								<div>RUBIA</div>
								<div className="sub">Restaurant</div>
							</div>
							<div className="links-wrapper">
								<div>
									<div className="hours-text">{hour.rubiaBrunch}</div>
									<div className="link-text">
										<a
											href="https://storage.googleapis.com/static.rubia.co.jp/menu_pdf/1F_BRUNCH.pdf"
											target="_blank"
											rel="noopener"
										>
											BRUNCH MENU
										</a>
									</div>
								</div>
								<div>
									<div className="hours-text">{hour.rubiaDinner}</div>
									<div className="link-text">
										<a
											href="https://storage.googleapis.com/static.rubia.co.jp/menu_pdf/1F_DINNER.pdf"
											target="_blank"
											rel="noopener"
										>
											DINNER MENU
										</a>
									</div>
								</div>
							</div>
						</div>
					</ScrollAndShow>

					<ScrollAndShow delay={1000}>
						<div className="divider pc" />
					</ScrollAndShow>

					<ScrollAndShow delay={500}>
						<div className="floor second">
							<div className="floor-text">2F</div>
							<div className="floor-name-text">
								<div>TAHONA</div>
								<div className="sub">Mezcal & Tequila Café Bar</div>
							</div>
							<div className="links-wrapper tahona">
								<div>
									<div className="hours-text">{hour.tahona}</div>
									<div className="link-text">
										<a
											href="https://storage.googleapis.com/static.rubia.co.jp/menu_pdf/2F_BAR.pdf"
											target="_blank"
											rel="noopener"
										>
											MENU{" "}
										</a>
									</div>
								</div>
							</div>
						</div>
					</ScrollAndShow>
				</div>

				<div className="rental-space">
					<ScrollAndShow>
						<div className="table-wrapper">
							<div className="title font-lato">
								<div>RENTAL SPACE</div>
								<div className="tax">※表示価格は全て税込となっております。</div>
							</div>
							<div className="font-yu">
								<ul>
									<li className="key">
										<div>
											<div className="en font-lato">Pop Up Event</div>
											<div className="ja font-yu">ポップアップイベント</div>
										</div>
									</li>
									<li className="value">
										{i18n.language === "ja"
											? rentalSpace.popUpEvent
											: rentalSpace.popUpEventEn}
									</li>
								</ul>
								<ul>
									<li className="key">
										<div>
											<div className="en font-lato">Rental Space</div>
											<div className="ja font-yu">レンタルスペース</div>
										</div>
									</li>
									<li className="value">
										{i18n.language === "ja"
											? rentalSpace.rentalSpace
											: rentalSpace.rentalSpaceEn}
									</li>
								</ul>
								<ul>
									<li className="key">
										<div>
											<div className="en font-lato">
												Private Party with Food
											</div>
											<div className="ja font-yu">
												お食事付きのパーティープラン
												<br />
												(ドリンクキャッシュオン)
											</div>
										</div>
									</li>
									<li className="value">
										{i18n.language === "ja"
											? rentalSpace.privateParty
											: rentalSpace.privatePartyEn}
									</li>
								</ul>
								<ul>
									<li className="key">
										<div>
											<div className="en font-lato">Facility</div>
											<div className="ja font-yu">付帯設備</div>
										</div>
									</li>
									<li className="value">
										{i18n.language === "ja"
											? rentalSpace.facility
											: rentalSpace.facilityEn}
									</li>
								</ul>
							</div>
						</div>
					</ScrollAndShow>

					<ScrollAndShow>
						<a
							className="inquiry-form-button font-yu"
							href="https://bit.ly/2UL6yJf"
							target="_blank"
						>
							お問い合わせフォーム
						</a>
					</ScrollAndShow>

					<ScrollAndShow>
						<div className="private-party font-yu">
							<div className="title ja">貸切パーティー・ポリシー</div>
							<div className="description">
								{i18n.language === "ja"
									? privateParty.policy
									: privateParty.policyEn}
							</div>
						</div>
					</ScrollAndShow>

					<ScrollAndShow>
						<a
							className="inquiry-form-button font-yu"
							href="https://bit.ly/2UL6yJf"
							target="_blank"
						>
							お問い合わせフォーム
						</a>
					</ScrollAndShow>
				</div>
			</div>
		</Layout>
	);
};

export default MenuPage;
